<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal"><v-icon>fas fa-times-circle</v-icon></v-btn>
                <v-toolbar-title>Generar reporte de asistencia</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="downloadExcel">
                    <v-icon left>fas fa-file-excel</v-icon> Generar reporte
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import SingleCalendar from '@/components/shared/SingleCalendar.vue';
    import AsistenciaDataService from '@/service/empleado/asistenciaDataService';
    import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

    export default {
        name: 'ExcelExportAsistencia',
        props: {
            dialog: { type: Boolean, default: false },
            handleModal: { type: Function }
        },
        components: { SingleCalendar },
        data() {
            return {
                startDate: getToday().toFormat('yyyy-MM-dd'),
                endDate: getToday().plus({ days: 1 }) .toFormat('yyyy-MM-dd')
            }
        },
        methods: {
            downloadExcel() {
                const startDate = getDateLocaleNatural(this.startDate);
                const endDate = getDateLocaleNatural(this.endDate);

                AsistenciaDataService.downloadExcelByDateRange({ startDate, endDate  }).then(resp => {
                    if( resp.status == 204 ) {
                        this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
                    } else {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute('download', `Lista_de_asistencias_${startDate}_${endDate}.xlsx`);

                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                }).catch(err => {
                    this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err.response.data, showConfirmButton: false, timer: 3500 });
                });
            }
        }
    }
</script>