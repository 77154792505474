import { http, authHeader } from '@/http-commons';

class AsistenciaDataService {
    create( asistencias ){
        return http.post('api/v1/asistencia/create', asistencias, { headers: authHeader()});
    }
    table( params ){
        return http.get('api/v1/asistencia/table', { params, headers: authHeader() });
    }
    downloadExcelByDateRange( params ){
        return http.get(`api/v1/asistencia/downloadExcelByDateRange/${params.startDate}/${params.endDate}`, { headers: authHeader(), responseType: 'blob' });
    }
}
export default new AsistenciaDataService();